import React from "react";
import { isAndroid, isIOS, isMacOs } from "react-device-detect";

const DownloadButton = ({ link1, link2, text }) => {
  const openAppStore = (link1, link2, text) => {
    if (isAndroid) {
      // Open the Google Play Store
      window.location.href = link1;
    } else if (isIOS) {
      // Open the Apple App Store
      window.location.href = link2;
    } else if (isMacOs) {
      window.location.href = link2;
    } else {
      window.location.href = link1;
    }
  };

  return (
    <div>
      <button
        onClick={() => openAppStore(link1, link2, text)}
        className=" mt-1"
      >
        {" "}
        {text}
      </button>
    </div>
  );
};

export default DownloadButton;
