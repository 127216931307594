import React from 'react'

const Offer = () => {
  return (
    <div className='pt-10 pb-20 text-center z-20 relative'>
       
       <h2 className='text-3xl lg:text-5xl text-[#313131] font-light mt-10 '>What <strong className='font-bold'>We Offer</strong></h2>
       {/* Story Map  */}

        <div className='flex flex-col lg:flex-row items-start lg:items-center  mt-20 lg:mt-40 justify-start'>
          <div className='flex items-center justify-center'>
            <img src="/images/Group 789.svg" 
              alt="Multiple Story Templets" 
              className='w-[150px] mb-2 lg:w-[500px]'
              />
              <div className='flex flex-col lg:hidden items-center justify-center'>
              <h2 className='text-xl w-[200px] lg:text-3xl font-bold'>100+ Story Templates</h2>
              <a 
            target={"_blank"}  rel="noreferrer" 
            href="https://play.google.com/store/apps/details?id=instagramstory.storymaker.android.layout" >
              <img src="/images/icon/google-play-badge.png" className='w-28 lg:w-44' alt="Google" />
            </a>
                  {/* <button className='bg-[#FF6200] text-white rounded-3xl space-x-1 flex items-center justify-center w-32 mt-4 px-2 py-1'>
                  <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-6 h-6'/>
                  <div>
                    <h2 className='text-xs font-bold'>StoryMap </h2>
                    <p className='text-[8px]'>Story maker & Layout</p>
                  </div>
                 </button> */}
              </div>
            

          </div>
           
            <div className='w-56  ml-20 lg:ml-0 lg:w-96 flex flex-col items-center'>
            <h2 className='text-xl lg:text-3xl hidden lg:block font-bold'>100+ Story Templates</h2>
            <p  className='text-xs lg:text-sm leading-6 font-sans '> Explore IG story templates like birthday templates, love templates, fashion templates, festival templates, and more. To design story perfectly, you can find artsy filters, funky stickers and can also edit text to your story templates </p>
            <a 
            target={"_blank"}  rel="noreferrer" 
            href="https://play.google.com/store/apps/details?id=instagramstory.storymaker.android.layout" >
              <img src="/images/icon/google-play-badge.png" className='hidden lg:block w-28 lg:w-44' alt="Google" />
            </a>
             {/* <button className='bg-[#FF6200] hidden text-white rounded-3xl lg:flex items-center justify-center w-52 mt-4 px-4 py-1'>
               <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-10 h-10'/>
               <div>
                <h2 className='font-bold'>StoryMap </h2>
                <p className='text-xs'>Story maker & Layout</p>
               </div>
             </button> */}
            </div>
        </div>


{/* Radio Stations */}

        <div className='flex lg:flex-row flex-col mt-20 lg:-mt-20 items-end  justify-end'>
          <div className='flex items-center justify-center'>
          <div className='flex  lg:hidden flex-col items-center justify-center'>
                    <h2 className='text-xl lg:text-3xl w-[160px]  font-bold'>30K+ Radio Stations</h2>
                    <a 
                      target={"_blank"}  rel="noreferrer" 
                      href="https://play.google.com/store/apps/details?id=radiofm.onlineradio.radiostaion.musicplayer.mp3" >
                        <img src="/images/icon/google-play-badge.png" className='w-28 lg:w-44' alt="Google" />
                    </a>
                    {/* <button className='bg-[#FF6200] text-white rounded-3xl space-x-1 flex items-center justify-center w-32 mt-4 px-2 py-1'>
                  <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-6 h-6'/>
                  <div>
                    <h2 className='text-xs font-bold'>StoryMap </h2>
                    <p className='text-[8px]'>Story maker & Layout</p>
                  </div>
                   </button> */}
                </div>
              <img src="/images/Group 788.svg" 
                alt="200+ Radio Channels" 
                className='w-[200px] block lg:hidden lg:w-[500px]'
                />
               
          </div>
      
            <div className='w-56  mr-32 lg:mr-0 lg:w-96 flex flex-col items-center'>
            <h2 className='text-xl lg:text-3xl hidden lg:block font-bold'>30K+ Radio Stations</h2>
            <p className='text-xs lg:text-sm leading-6 font-sans '>Next gen of musical journey with Radio App. Explore country-wise and category based radio stations to level up your online radio journey. You can discover news, music, sports and to experience all-in-one radio app, try audio recorder, sleep timer and alot more.</p>
            <a 
                      target={"_blank"}  rel="noreferrer" 
                      href="https://play.google.com/store/apps/details?id=radiofm.onlineradio.radiostaion.musicplayer.mp3" >
                        <img src="/images/icon/google-play-badge.png" className='hidden lg:block w-28 lg:w-44' alt="Google" />
            </a>
             {/* <button className='bg-[#FF6200] hidden  text-white rounded-3xl lg:flex items-center justify-center w-52 mt-4 px-4 py-1'>
               <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-10 h-10'/>
               <div>
                <h2 className='font-bold'>StoryMap </h2>
                <p className='text-xs'>Story maker & Layout</p>
               </div>
             </button> */}
            </div>
            <img src="/images/Group 788.svg" 
            alt="200+ Radio Channels" 
            className='w-[200px] hidden lg:block lg:w-[500px]'
            />
        </div>












        <div className='flex flex-col lg:flex-row items-start lg:items-center mt-20 lg:mt-40 justify-start'>

       <div className='items-center justify-center flex '>
       <img src="/images/Group 787.svg" 
           alt="200+ Radio Channels" 
           className='w-[200px] lg:w-[500px]'/>
           <div className='lg:hidden'>
           <h2 className='text-xl lg:text-3xl w-[160px]  font-bold'>Various Editing Options</h2>
           <a 
                      target={"_blank"}  rel="noreferrer" 
                      href="https://play.google.com/store/apps/details?id=com.videomaster.editor" >
                        <img src="/images/icon/google-play-badge.png" className='w-28 lg:w-44' alt="Google" />
                    </a>
           {/* <button className='bg-[#FF6200] text-white rounded-3xl 
           space-x-1 flex items-center justify-center w-32 mt-4 px-2 py-1'>
              <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-6 h-6'/>
              <div>
               <h2 className='text-xs font-bold'>StoryMap </h2>
               <p className='text-[8px]'>Story maker & Layout</p>
              </div>
            </button> */}
           </div>
       </div>
      
          
       
           <div className='w-56   lg:w-96 ml-20 lg:ml-0 flex flex-col items-center'>
           <h2 className='text-xl lg:text-3xl hidden lg:block font-bold'>Various Editing Options</h2>
           <p className= 'text-xs lg:text-sm leading-6 font-sans '>Perfect video editor to boost your social media appearance. You can design your videos with fascinating stickers, easy to add music, share thoughts through add text functionality and many other editing benefits with free video editor.</p>
           <a 
                      target={"_blank"}  rel="noreferrer" 
                      href="https://play.google.com/store/apps/details?id=com.videomaster.editor" >
                        <img src="/images/icon/google-play-badge.png" className='hidden lg:block w-44' alt="Google" />
                    </a>
            {/* <button className='bg-[#FF6200] text-white hidden rounded-3xl lg:flex items-center justify-center w-52 mt-4 px-4 py-1'>
              <img src="/images/icon/Rectangle 66@2x.png" alt="Story maker" className='w-10 h-10'/>
              <div>
               <h2 className='font-bold'>StoryMap </h2>
               <p className='text-xs'>Story maker & Layout</p>
              </div>
            </button> */}
           </div>
           
       </div>


    </div>
  )
}

export default Offer