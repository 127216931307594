import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "../Screens/faq.css";

const KnifeHitFaq = () => {
  return (
    <div className=" mx-auto w-[90%] lg:w-3/4 mt-14 grid grid-cols-1 lg:grid-cols-2 gap-10 font-sans">
      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">1. What is the Knife Show game?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              APS Knife Show game is a casual knife game available to play
              online and offline. In this game, players can practice their
              knife-throwing skills by aiming at the required targets.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">2. How do I play the APS Knife game?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              To play the game, you need to aim and hit the target. Make sure to
              hit the target accurately without hitting any other knives already
              stuck in the target.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                3. Is the Knife throwing game free to play?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this knife throw game is free to download and play. You can
              play it anytime anywhere with online or offline mode.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                4. Why do I have to play the game from the start whenever I’m
                losing any stage?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              As the game is designed with the theme of “Endless” gameplay. So,
              whenever you’ll lose any stage, you have to play from the start.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                5. Are there different levels or challenges in the Knife game?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, the game usually offers multiple levels or challenges of
              increasing hyper-casual game difficulty. As you progress, you may
              explore boss levels, challenges or other gameplay variations to
              make it more challenging.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                6. Is it possible to lower the default game sound/music?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can manage the sound by going to the settings menu. You
              can find this menu on the home page.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">
                7. Can I play the hyper-casual Knife game offline?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can play this game offline but few gaming knives or
              rewarded items are only available in an online mode. However, we
              can say, the core gameplay is typically available online and
              offline.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                8. Can I customize the knives or targets in this game?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can choose and play with a variety of knives like
              daggers, exotic knives, and more. Some upcoming versions may offer
              options to unlock or purchase target themes.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Query not mentioned here! How can I contact you?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              If you have not found your query here, you can get in touch with
              our support team so we can help you better. <br />
              You may contact us at{" "}
              <a href="mailto: feedback@appspacesolutions.in">
                <strong>feedback@appspacesolutions.in</strong>
              </a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default KnifeHitFaq;
