import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReViews = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode:true,
    slidesToShow:3,
    slidesToScroll: 2,
    centerPadding:"0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode:true,
        }
      }
    ]
  };


  const CutomReview=({name,review,image})=>{
    return (
      <div className='flex  flex-col relative 
      pt-24 lg:pt-32 mt-10 items-center bg-white rounded-[60px]  w-[80%] justify-center'>
      <img src={image} 
        alt={name}
        className='w-20 rounded-3xl border-4 h-24 lg:h-32
        drop-shadow-lg lg:w-32 absolute  -top-10'
      />
      <h3><strong>{name}</strong></h3>
      <img src="/images/Group 746.svg"
      className='w-10 lg:w-20'
       alt="stars" />
       <p className='w-full text-[10px] lg:text-xs font-sans h-52 lg:h-64 px-2 lg:px-10 py-4'>{review}</p>
   </div>
    )
  }


  return (
    <div className='mt-10 text-center bg-gradient-to-r    text-black from-[#558CF7] to-[#0D45B5] h-[800px]'>
      <h2 className='text-3xl lg:text-5xl pt-20 font-thin font-sans text-white'> Our User <strong className='font-bold'>Loves Us</strong></h2>
      <div  className='px-10 lg:px-24  mx-10   mt-32'>
        <Slider id='reviewDiv'  {...settings}>
       
            <CutomReview 
            image={"/images/new/reviewone.webp"}
            name="one" 
            review="Best platform for usable apps. I like collage maker and stack ball as collage maker helps me to design my new photos and whenever i feel the boredm, i simply play stack ball to level up my mood. Best apps so far. Thankyou Team"
            />
            <CutomReview 
            image={"/images/new/rosie builds.webp"}
            name="rosie builds" 
            review="Team work leads to best development. I really like video editor and when I installed video editor to my device, I automatically moved forward to try other apps and now my favourite apps are radio app and this stack ball is best amongst all. So happy!!"
            />
            <CutomReview 
            image={"/images/new/mansi-shrivastav.webp"}
            name="Mansi" 
            review="I love writing quotes and to design my quotes in a presentable manner, I tried quotes app and I simply love its templates and free daily quotes. These quotes are loved by my friends as well but your games are also best. I really love it. Make more games apps."
            />
        </Slider>
       
      </div>
   
    </div>
  )
}

export default ReViews