import React from "react";

import download from "../../images/calmverse/images/playstore_icon.png";
function CardList({ cards }) {
  return (
    <div className="cards">
      {cards.map((card) => (
        <div key={card.id} className="card">
          <a href={card.link} rel="noreferrer" target="_blank">
            {" "}
            <img src={card.img} alt="" />
          </a>
          <h2>{card.title}</h2>
          <p>{card.description}</p>
          <a href={card.download} target="_blank" rel="noreferrer">
            <img src={download} alt="" className="download-game" />
          </a>
        </div>
      ))}
    </div>
  );
}

export default CardList;
