// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, initializeAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADrlLw0-Vd3tiK_FC29chDvJAducyVNlw",
  authDomain: "appspace-solutions.firebaseapp.com",
  projectId: "appspace-solutions",
  storageBucket: "appspace-solutions.appspot.com",
  messagingSenderId: "565418644636",
  appId: "1:565418644636:web:1ec57235182ad6d37c8959",
  measurementId: "G-QHYGSZ3MEJ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
 getAnalytics(app);


initializeAnalytics(app)
export const firestore = getFirestore();

