import React from 'react';
import Slider from "react-slick";
import arrow from "./assets/arrow.svg";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 


import poster1 from "./assets/banner/poster1.jpg"
import poster2 from "./assets/banner/poster2.jpg"
import poster3 from "./assets/banner/poster3.jpg"
import poster4 from "./assets/banner/poster4.jpg"
import poster6 from "./assets/banner/poster6.jpg"
import poster7 from "./assets/banner/poster7.jpg"

const posters = [
  {
   image: poster1, alt: "Poster 1",
   link:"https://play.google.com/store/apps/details?id=com.filemanager.fileexplorer.junkcleaner"
  },
  {
   image: poster2, alt: "Poster 2",
   link:"https://play.google.com/store/apps/details?id=com.calmsleep.android"
  },
  {
   image: poster3, alt: "Poster 3",
   link:"https://play.google.com/store/apps/details?id=collagemaker.photoeditor.postcreator"
  },
  {
    image: poster4, alt: "Poster 4" ,
    link:"https://play.google.com/store/apps/details?id=com.video.videoplayer.allformat"
  },
  {
    image: poster6, alt: "Poster 6",
    link:"https://play.google.com/store/apps/details?id=com.appspace.fast.secure.vpn"
  },
  {
  image: poster7, alt: "Poster 7",
    link:""
  }
];



export default function MoreApps() {

  const settings = {
  dots: true,
  speed: 100,
  slidesToShow: 1, 
  slidesToScroll: 1, 
  infinite: false,
  autoplaySpeed: 1000,
  arrows: false, 
  };

  return (
    <section className='w-full bg-black pt-16 px-2 pb-5'>
   
      <div className='flex justify-between items-center md:mx-20'>
        <h4 className='text-white font-inter text-lg lg:text-2xl font-semibold leading-normal m-0'>
          MORE APPS
        </h4>
        <button className='flex gap-2 justify-center items-center text-xs lg:text-sm bg-[#393232] text-white rounded-full px-3 py-2'>
          All Apps <img src={arrow} alt='Arrow' className='w-4 h-4 lg:w-5 lg:h-5'/>
        </button>
      </div>
      
      <div className='text-white font-inter text-xs md:mx-20  sm:text-sm md:text-base mt-10 pr-5 pb-10'>
        To build the best utility apps & provide digital solutions to the everyday requirements of users and enhance their app-using experience.
      </div>

      
      <section className='w-full bg-black py-8 flex justify-center'>
      <div className='w-[100%] sm:w-[90%] md:w-[80%]'>
        <Slider {...settings}>
          {posters.map((poster, index) => (
            <a href={poster.link}>
            <div key={index} className='md:px-2'>
              <img 
                src={poster.image} 
                alt={poster.alt} 
                className='w-full h-auto object-cover rounded-lg' 
              />
            </div>
            </a>
          ))}
        </Slider>
      </div>
    </section>
    </section>
  );
}

