import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
// import FooterNew from '../Comp/FooterNew'
import AppHeader from "../Comp/AppHeader";

const FaqScreen = () => {
  const [searchState, setSearchState] = useState();
  const search = useLocation();

  const featchSearch = () => {
    switch (search.pathname) {
      case "/faq/collage-maker":
        setSearchState("collageMaker");
        break;
      case "/faq/video-editor":
        setSearchState("videoEditor");
        break;
      case "/faq/calm-sleep":
        setSearchState("calmSleep");
        break;
      case "/faq/knife-hit":
        setSearchState("knife-hit");
        break;
      default:
        setSearchState("collageMaker");
        break;
    }
  };

  useEffect(() => {
    featchSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="pb-44  relative  font-sans" id="faqPageCss">
      <AppHeader name="faq" indexSlide={0} />

      <h1 className=" pt-32 text-2xl  text-black lg:text-4xl font-semibold text-center">
        Frequently Asked Questions
      </h1>
      <div className="flex items-center justify-center space-x-6 lg:space-x-20 mt-10 ">
        <Link
          to={"collage-maker"}
          onClick={() => setSearchState("collageMaker")}
          className="flex flex-col items-center justify-center space-y-3"
        >
          <img
            src="/images/icon/Rectangle 66@2x.png"
            alt="Collage Maker"
            className={`lg:w-16 lg:h-16 w-10 h-10
          hover:scale-125 transition-all
          ${
            searchState === "collageMaker"
              ? "scale-125   rounded-2xl"
              : "scale-100"
          }
          `}
          />
          <p
            className={`${
              searchState === "collageMaker"
                ? "text-black block"
                : "text-gray-500 hidden"
            } font-semibold`}
          >
            Collage Maker
          </p>
        </Link>
        <Link
          to={"video-editor"}
          onClick={() => setSearchState("videoEditor")}
          className="flex flex-col items-center justify-center space-y-3"
        >
          <img
            src="/images/icon/Rectangle 17@2x.png"
            alt="Video Editor"
            className={`lg:w-16 lg:h-16 w-10 h-10
          hover:scale-125 transition-all
          ${searchState === "videoEditor" ? "scale-125" : "scale-100"}
          `}
          />
          <p
            className={`${
              searchState === "videoEditor"
                ? "text-black block"
                : "text-gray-500 hidden"
            } font-semibold`}
          >
            Video Editor
          </p>
        </Link>
        <Link
          to="calm-sleep"
          onClick={() => setSearchState("calmSleep")}
          className="flex flex-col items-center justify-center space-y-3"
        >
          <img
            src="/images/icon/calm.png"
            alt="Calm Sleep "
            className={`lg:w-16 lg:h-16 w-10 h-10
          hover:scale-125 transition-all
          ${searchState === "calmSleep" ? "scale-125" : "scale-100"}
          `}
          />
          <p
            className={`${
              searchState === "calmSleep"
                ? "text-black block"
                : "text-gray-500 hidden"
            } font-semibold`}
          >
            Calm & Sleep
          </p>
        </Link>
        <Link
          to="knife-hit"
          onClick={() => setSearchState("knife-hit")}
          className="flex flex-col items-center justify-center space-y-3"
        >
          <img
            src="/images/icon/knifeIcon.webp"
            alt="Knife Hit "
            className={`lg:w-16 lg:h-16 w-10 h-10
          hover:scale-125 transition-all
          ${searchState === "knife-hit" ? "scale-125" : "scale-100"}
          `}
          />
          <p
            className={`${
              searchState === "knife-hit"
                ? "text-black block"
                : "text-gray-500 hidden"
            } font-semibold`}
          >
            Knife Hit
          </p>
        </Link>
      </div>
      <Outlet />
      {/* </div> */}

      {/* <FooterNew/> */}
    </main>
  );
};

export default FaqScreen;
