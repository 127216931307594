import React, { useEffect, useState } from "react";
import AppHeader from "../Comp/AppHeader";
import axios from "axios";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBlogs = async () => {
    setLoading(true);
    const data = await axios.get(
      "https://euphonious-wisp-a451df.netlify.app//api/text"
    );
    const innerData = await data.data;
    setLoading(false);
    setBlogs(innerData);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading === true) {
    return (
      <div>
        <AppHeader name="blogs" indexSlide={0} />
        <h1 className="text-2xl  h-screen font-semibold flex flex-col items-center justify-center">
          Coming Soon...
        </h1>
      </div>
    );
  }

  return (
    <div className="mb-44">
      <AppHeader name="blogs" indexSlide={0} />
      <h1 className="pt-32 text-center text-3xl lg:text-5xl">
        Step inside & <br /> <strong>Explore our Blogs</strong>
      </h1>
      <div className="grid grid-cols-1 place-items-center gap-14 lg:gap-32 mt-20 lg:mt-32 mx-3 lg:mx-32">
        {blogs.map((value, index) => {
          return (
            <div
              key={index}
              className="w-full flex flex-col lg:flex-row items-start lg:space-x-10  justify-center"
            >
              <Link to={value.blogLink} className="w-full h-54 lg:h-96 ">
                <img
                  src={value.coverImage}
                  className="w-full h-54 lg:h-96 object-cover rounded-xl transition-all hover:opacity-70"
                  alt="Calm Sleep Cover "
                />
              </Link>
              <div className="w-full lg:w-1/2 h-32  lg:h-96 flex flex-col items-start justify-start mt-3 lg:justify-around">
                <div className="">
                  <p className="font-medium inline lg:block lg:font-semibold text-black text-sm lg:text-xl">
                    {value.name}{" "}
                    <p className="lg:hidden text-xs inline ">{`| ${value.datePublished}`}</p>
                  </p>
                  <Link to={value.blogLink}>
                    <h2 className="text-lg lg:text-3xl font-semibold mt-4 transition-all hover:text-gray-700">
                      {value.title}
                    </h2>
                  </Link>
                </div>
                <p className="text-gray-600 text-xs hidden lg:block lg:text-lg -mt-10 lg:-mt-6">
                  {value.datePublished}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blogs;
