import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import AppHeader from "../Comp/AppHeader";
import parse from "html-react-parser";

const DynamicBlog = () => {
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);

  let searchParams = useParams();

  const fetchBlog = async () => {
    setLoading(true);
    const data = await axios.get(
      "https://euphonious-wisp-a451df.netlify.app/api/text"
    );
    const innerData = await data.data;
    const blogData = innerData.filter(function (el) {
      return el.link === searchParams.blogId;
    });

    setLoading(false);
    setBlog(blogData[0]);
    // console.log(innerData)
  };

  useEffect(() => {
  
    fetchBlog();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading === true) {
    return (
      <h1 className="text-2xl  h-screen font-semibold flex flex-col items-center justify-center">
        Loading...
      </h1>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{blog.title}</title>
        <meta name="description" content={blog.metaDescription} />
      </Helmet>
      <AppHeader name="blogs" />
      <main className="pt-32 mx-6 lg:mx-44 font-sans pb-32" id="blogContent">
        <h1 className="lg:text-4xl text-2xl font-semibold text-center">
          {blog.coverTitle}
        </h1>
        <img
          src={blog.coverImage}
          alt={blog.title}
          className="mt-10 rounded-2xl h-[250px] object-cover lg:h-[500px] mx-auto"
        />
        <p className="mt-10">{parse(blog.coverText ?? "")}</p>
        <div className="mt-10">{parse(blog.content ?? "")}</div>
        <div className="mt-24">
          <h2 className="text-2xl font-semibold">{blog.blogoverTitle}</h2>
          <div>{parse(blog.blogoverText ?? "")}</div>
        </div>
      </main>
    </>
  );
};

export default DynamicBlog;
