import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "../Screens/faq.css";

const CalmSleepFaq = () => {
  return (
    <div className=" mx-auto  w-[90%] lg:w-3/4 mt-14 grid grid-cols-1 lg:grid-cols-2 gap-10 font-sans">
      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg "
            >
              <p className="w-3/4"> What is Calm Sleep and Meditate?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Calm Sleep and Meditate app is designed to help users relax,
              meditate, and sleep better. The app provides guided meditations,
              soothing sleep sounds, and other features like online radio to
              help users find calmness and improve their overall well-being.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I listen calm sleep music with sleep timer?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this app lets you enable a sleep timer so you can fall asleep
              with calm music and make it easier to to turn down and
              automatically end the music{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                How can I change the default <br /> background of this app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              1. Open the app and tap on the top-left corner. <br />
              <img
                src="/images/Faqs/calm-sleep-background.webp"
                className="w-32 rounded-2xl object-cover mt-6 mb-10"
                alt="Calm sleep background "
              />
              2. Select and set the background as per your choice. <br />
              <img
                src="/images/Faqs/calm-sleep-set-background.webp"
                className="w-32 rounded-2xl object-cover mt-6"
                alt="Calm sleep background"
              />
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">Not able to find your favorites?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              If you have saved your favourite radio stations or calm sleep
              music then you can find them in the "Me" section of the app.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I use the app without an internet connection?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, some features of the app can be accessed offline, such as an
              offline music library. However, other features may require an
              internet connection, such as radio stations, calm sleep tunes and
              other features.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                How many music sounds are there in the calm sleep app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              In this app, you can explore 400+ relaxing sounds based on a
              variety of categories including, lullabies, Rain music, Lo-fi,
              acoustic, divine, and more.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">
                Is it possible to create a calm sleep music playlist ?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can create your own playlist from the list of relaxing
              sounds of multiple categories. Just tap on your favourite music
              category, and tap on the playlist button to create your own
              favourite playlist.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                How can I switch the country to listen to online radio stations?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              1. Go to top left corner of the Radio functionality screen <br />
              <img
                src="/images/Faqs/calm-sleep-topLeft.webp"
                className="w-32 rounded-2xl object-cover mt-6 mb-8"
                alt="Calm sleep Radio functionality"
              />
              2. Tap on the "Country" logo and change your preferred country
              accordingly.
              <img
                src="/images/Faqs/calm-sleep-Country.webp"
                className="w-32 rounded-2xl object-cover mt-6"
                alt="Calm sleep Radio functionality"
              />
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Is Calm sleep and Meditate app is free to use?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this app is free to use, you can listen to online and offline
              music along with multiple radio stations. You don't have to buy
              any premium package to enjoy its different music categories.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Query not mentioned here! How can I contact you?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900">
              If you have not found your query here, you can get in touch with
              our support team so we can help you better. <br />
              You may contact us at{" "}
              <a href="mailto: feedback@appspacesolutions.in">
                <strong className="break-words">feedback@appspacesolutions.in</strong>
              </a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CalmSleepFaq;
