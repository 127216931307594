import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "../Screens/faq.css";

const VideoEditorFaq = () => {
  return (
    <div className=" mx-auto w-[90%] lg:w-3/4 mt-14 grid grid-cols-1 lg:grid-cols-2 gap-10 font-sans">
      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">What is a video editor app?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              A video editor app allows users to edit and enhance videos on
              their smartphones. It typically provide features such as trimming,
              cutting, merging, adding effects, and more.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">Is it free to use a video editor app?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this video editor app is free to download and use, you can
              explore and enjoy video editing with free tools, and can make your
              videos look more fascinating{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I export my edited video from the app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this app allows you to export your edited video to your
              device's camera roll and share them with your friends and family
              via any sharing platform{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                What formats can a video editor app export to?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              It supports almost all the popular video formats such as MP4, MOV
              and more. You can play and edit every video of your choice.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                How Can I rename the video title as per my preference?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              You can rename your edited videos by just few steps; <br />
              1. Tap on the three dots <br />
              <img
                src="/images/Faqs/video-editor-three-dots.webp"
                className="w-32 rounded-2xl object-cover mt-6"
                alt="Video editor  rename"
              />
              2. Click "Rename" and change the title accordingly <br />
              <img
                src="/images/Faqs/video-editor-rename.webp"
                className="w-32 rounded-2xl object-cover mt-6"
                alt="Video Editor rename"
              />
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Is it possible to add text to only specific part of the video?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can manage the text and adjust it as per your preference.
              Drag the slide bar and manage the text timing accordingly
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">
                How can I manage the default audio and added music volume in my
                video?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Video editor lets you edit the music just by tapping to volume
              option as shown. Just tap on the volume button and manage the
              video editing with no trouble.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I edit photos or just create collage with this app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, with the collage maker app, you can edit your photos with
              multiple editing tools along withj free style collages and grid
              collage maker.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Is it possible to share this app with my friends ?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can share this app with other people via any social media
              platform or any other apps sharing platforms
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Query not mentioned here! How can I contact you?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              If you have not found your query here, you can get in touch with
              our support team so we can help you better. <br />
              You may contact us at{" "}
              <a href="mailto: feedback@appspacesolutions.in">
                <strong>feedback@appspacesolutions.in</strong>
              </a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default VideoEditorFaq;
