import React from "react";
import AppHeader from "../Comp/AppHeader";
import FooterNew from "../Comp/FooterNew";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AppSpace Solutions | About Us</title>
        <meta
          name="description"
          content="AppSpace Solutions is a leading software development company that specializes in creating innovative solutions for users to ease there daily activities."
        />
      </Helmet>
      <AppHeader name="about" />
      <main className="font-sans pt-16 overflow-hidden">
        <img
          src="/images/Group 852.svg"
          className="w-[75%] mx-auto mt-6"
          alt="home"
        />
        <h1 className="text-3xl lg:text-5xl mt-8 font-semibold text-center">
          About us
        </h1>
        <p className="text-xs lg:text-lg mt-4 font-light text-center mx-10 lg:mx-32">
          AppSpace Solutions, Founded in 2021, is an App Development Company
          that is ready to serve the best-of-breed technology. Our proficient
          team diligently works towards establishing a lifelong relationship
          with each of our users and we propose to do this by developing
          top-notch Android, iOS, and the most demanding Unity apps like casual
          fun games. We have developed several applications like Collage Maker,
          Stack Ball, Video Editor, Quotes, Story maker, Radyo, Calm Sleep:
          Relax and meditate, Scan Doc, Video Downloader for all files, and TIC
          TAC TOE game.
        </p>
        <div className="bg-about-bg text-white px-6 lg:px-44  py-16 mt-10">
          <div className="flex items-center space-x-6 ">
            <img src="/images/Group 810.svg" alt="Vision" className="w-28" />
            <div>
              <h2 className="text-3xl lg:text-5xl font-light">
                Our <strong className="font-semibold">Vision</strong>
              </h2>
              <p className="text-xs lg:text-sm mt-4 font-light text-left ">
                In the field of application development, we focus on developing
                and managing productivity and gaming apps to elevate your mobile
                app experience. We strive to explore our users' requirements and
                develop appropriate solutions using cutting-edge technology.
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-6 mt-10">
            <div>
              <h2 className="text-3xl lg:text-5xlfont-light text-right">
                Our <strong className="font-semibold">Mission</strong>
              </h2>
              <p className="text-xs lg:text-sm mt-4 font-light text-right ">
                We focus on developing innovative ideas and transforming them
                into flawless applications. What distinguishes us from others is
                our determination to prioritize the needs of our app users, and
                thus we strive to meet their expectations by creating a
                trustworthy IT space
              </p>
            </div>
            <img src="/images/Group 808.svg" alt="Mission" className="w-24" />
          </div>
        </div>
        <h2 className="text-center font-light text-3xl lg:text-5xl mt-16 mb-10">
          <strong className="font-bold">We Work</strong> On
        </h2>
        <div
          className="flex  flex-col space-y-16 lg:space-y-0 lg:flex-row items-center mx-10 lg:mx-0 
        justify-center px-4 lg:px-24 space-x-4 mb-32 "
        >
          <div className="px-4 flex-col flex items-center justify-center shadow-[3px_4px_6px_-1px_rgb(0,0,0,0.1),3px_2px_4px_-2px_rgb(0,0,0,0.1)] pb-10 rounded-2xl w-[600px]">
            <div className="flex items-center justify-center flex-col">
              <img
                src="/images/Group 838.svg"
                alt="Android"
                className="w-32 lg:w-44"
              />
              <h2 className="text-3xl font-bold mt-6">Android</h2>
              <p className="text-xs lg:text-sm max-w-[40%] lg:max-w-[90%] lg:leading-7 h-32 lg:h-64 text-center mt-4">
                For billions of Android users, the simple and intuitive user
                interface of our apps will naturally blend with your lifestyle.
                To make your life smooth with the help of AI, we develop useful
                apps that are required on a daily basis.
              </p>
            </div>
          </div>
          <div className="px-4 flex-col flex items-center justify-center shadow-[3px_4px_6px_-1px_rgb(0,0,0,0.1),3px_2px_4px_-2px_rgb(0,0,0,0.1)] pb-10  rounded-2xl w-[600px]">
            <div className="flex items-center justify-center flex-col">
              <img
                src="/images/Group 846.svg"
                alt="IOS"
                className="w-32 lg:w-44"
              />
              <h2 className="text-3xl font-bold mt-6">iOS</h2>
              <p className="text-xs lg:text-sm max-w-[40%] lg:max-w-[90%] lg:leading-7 h-32 lg:h-64 text-center mt-4">
                For you who strive for perfection in all aspects of life and
                always go the extra mile to achieve goals, inspired by you we
                have created apps that accelerate your progress and relax you at
                the same time
              </p>
            </div>
          </div>
          <div className="px-4 flex-col flex items-center justify-center shadow-[3px_4px_6px_-1px_rgb(0,0,0,0.1),3px_2px_4px_-2px_rgb(0,0,0,0.1)] pb-10  rounded-2xl w-[600px]">
            <div className="flex items-center justify-center flex-col">
              <img
                src="/images/Group 851.svg"
                alt="Unity"
                className="w-32 lg:w-44"
              />
              <h2 className="text-3xl font-bold mt-6">Unity</h2>
              <p className="text-xs lg:text-sm max-w-[40%] lg:max-w-[90%] lg:leading-7 h-32 lg:h-64 text-center mt-4">
                Casual gamers like you are always on the hunt for new
                adventures, and to satisfy this quest for fun,
                excitement, we provide games with exceptional graphics, sound
                effects, challenging levels, and a promise to keep you engaged
                with these mind-boggling games.
              </p>
            </div>
          </div>
        </div>
      </main>
      <FooterNew bgColor="#ff6200" />
    </>
  );
};

export default AboutUsPage;
