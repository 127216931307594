import React, { useState } from "react";
import "./Games.scss";
import AppHeader from "../../Comp/AppHeader";
import Slider from "react-slick";
import juggler from "../../images/games/images/juggler.svg";
import playstore from "../../images/calmverse/images/playstore_icon.png";
import appstore from "../../images/calmverse/images/app_store_icon.png";
import war_tank from "../../images/games/images/war_tank.png";
import tap_tap_ball from "../../images/games/images/KF.png";
import stack_ball from "../../images/games/images/stack_ball.png";
import joker from "../../images/games/images/joker.png";
import locker from "../../images/games/images/locker.png";

import knife_show_title from "../../images/games/images/Knife_show_title.png";
import FilterBar from "./FilterBar";
import CardList from "./CardList";
import mobile_video from "../../images/games/images/Record_2023-09-20-11-54-52.mp4";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import FooterNew from "../../Comp/FooterNew";
import { Link } from "react-router-dom";
const Games = () => {
  const settings = {
    dots: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 1,
    centerPadding: "0px",
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    arrows: false,
    infinite: true,
    centerMode: false,
    fade: false,
    pauseOnDotsHover: false,
  };
  const settings2 = {
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,

    centerMode: true,
    arrows: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          arrows: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: false,
        },
      },
    ],
  };
  const initialCards = [
    {
      id: 1,
      title: "WAR TANK",
      category: "Popular",
      img: "https://play-lh.googleusercontent.com/EuOtJTKtNjP02ANO7avSDHty18yVcTCF9sGk5Q1m8rGrwn9QW_L6ziok9vIvKecN4HU=w240-h480-rw",
      description: "Command and dominate the battlefield in War Tank!",
      link: "https://play.google.com/store/apps/details?id=com.appspace.ballblast",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.ballblast",
    },

    {
      id: 2,
      title: "UNIFY 2048",
      category: "Arcade",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/2048-3D.gif",
      description: "Merge and match cubes with a 2048 3D look!",
      link: "https://play.rareprobgames.com/2048-3d/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.puzzle2048",
    },
    {
      id: 3,
      title: "ZIG ZAG ZONE",
      category: "Arcade",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/zigzag.gif",
      description: "Navigate the path with the Zig Zag game!",
      link: "https://play.rareprobgames.com/zig-zag/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.zigzag",
    },
    {
      id: 4,
      title: "WOODIE BRICKS",
      category: "Arcade",
      img: "https://play-lh.googleusercontent.com/e_8zrZeMjzFKsx5TcXPbOgCgJfzs_avCTGNR3kAq69AW7XftFwIngR76gBp1u-tMPa4=w480-h960-rw",
      description: "Stack, Strategize, and conquer with Woodie Brick",
      link: "https://play.google.com/store/apps/details?id=com.appspace.wood.block.puzzle",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.wood.block.puzzle",
    },
    {
      id: 5,
      title: "EGG DROP",
      category: "Popular",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/egg_drop.gif",
      description: "Take the Egg Drop Challenge and keep them intact.",
      link: "https://play.rareprobgames.com/egg-drop/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.eggdrop",
    },
    {
      id: 6,
      title: "FLAPPY BALL",
      category: "Popular",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/FLUFFY_BALL.gif",
      description: "Tap your way to victory and aim for obstacles!",
      link: "https://play.rareprobgames.com/Flappy-Ball/index.html",
      download:
        "https://play.google.com/store/apps/details?id=flappy.game.android",
    },
    {
      id: 7,
      title: "APS KNIFE SHOW",
      category: "Popular",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/Knife_hit.gif",
      description: "Slice up the Knife Show game with thrilling Boss level.",
      link: "https://play.rareprobgames.com/knifehits/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.knifehit",
    },

    {
      id: 8,
      title: "APS KNIFE SHOW",
      category: "Casual",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/Knife_hit.gif",
      description: "Slice up the Knife Show game with thrilling Boss level.",
      link: "https://play.rareprobgames.com/knifehits/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.knifehit",
    },
    {
      id: 9,
      title: "EGG DROP",
      category: "Casual",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/egg_drop.gif",
      description: "Take the Egg Drop Challenge and keep them intact.",
      link: "https://play.rareprobgames.com/egg-drop/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.appspace.eggdrop",
    },
    {
      id: 10,
      title: "FLAPPY BALL",
      category: "Casual",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/FLUFFY_BALL.gif",
      description: "Tap your way to victory and aim for obstacles!",
      link: "https://play.rareprobgames.com/Flappy-Ball/index.html",
      download:
        "https://play.google.com/store/apps/details?id=flappy.game.android",
    },

    // {
    //   id: 11,
    //   title: "STACK BALL",
    //   category: "Casual",
    //   img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/stack_ball.gif",
    //   description: "Drop, Smash, and Conquer the Stacked Tower.",
    //   link: "https://play.rareprobgames.com/stackball/index.html",
    //   download:
    //     "https://play.google.com/store/apps/details?id=com.dropstackball.game",
    // },
    {
      id: 12,
      title: "TAP TAP BALL",
      category: "Casual",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/tapTapShot.webp",
      description:
        " Keep the ball rolling with an addictive tapping adventure!",
      link: "https://play.rareprobgames.com/tap-tap-ball/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.aps.taptapball",
    },

    {
      id: 13,
      title: "TIC TAC TOE",
      category: "Casual",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/newGames/tic_tac_toe.webp",
      description: "Outsmart your opponent in this classic game ",
      link: "https://play.rareprobgames.com/tictactoe/index.html",
      download:
        "https://play.google.com/store/apps/details?id=appspace.tictactoe.game",
    },
    {
      id: 14,
      title: "APS 2048",
      category: "Puzzle",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/newGames/2048.webp",
      description: " Challenge your mind with the addictive 2048 game!",
      link: "https://play.rareprobgames.com/2048/index.html",
      download:
        "https://play.google.com/store/apps/details?id=com.aps.game2048.puzzle",
    },
    {
      id: 15,
      title: "WATER SORT",
      category: "Puzzle",
      img: "https://img.rareprob.com/img/default/RareProbImg/imagesRare/games/water-sort.webp",
      description: "Pour, Mix, and match colors to solve Water Sort Puzzle!",
      link: "https://play.rareprobgames.com/water-sort-puzzle/index.html",
      download:
        "https://play.google.com/store/apps/details?id=aps.watersort.colorpuzzle",
    },
    {
      id: 16,
      title: "APS Hidden Objects",
      category: "Puzzle",
      img: "https://play-lh.googleusercontent.com/7gsnJQDzou-EsbEB8g4vNd238EvtT5m6cnP_SDdtODbt1Omx9a8PVCwRRAdpK0wdjNA=w240-h480-rw",
      description: "Find the Hidden Objects with Your Observation Skills!",
      link: "https://play.google.com/store/apps/details?id=search.hidden.object.android.game",
      download:
        "https://play.google.com/store/apps/details?id=search.hidden.object.android.game",
    },
    {
      id: 17,
      title: "MINESWEEPER",
      category: "Puzzle",
      img: "https://play-lh.googleusercontent.com/UUlRJvxTOzGxvidQmwgBDRlNoZ_XB8eYQWWJzf4JpgQYSjtT8PvpcaFeu8z5mGysHlZf=w480-h960-rw",
      description: "Choose the numbers carefully in The Minesweeper",
      link: "https://play.google.com/store/apps/details?id=com.aps.minesweeper",
      download:
        "https://play.google.com/store/apps/details?id=com.aps.minesweeper",
    },

    // Add more cards with different categories
  ];

  const [cards, setCards] = useState(initialCards);
  const [selectedCategory, setSelectedCategory] = useState("Popular");

  const categories = ["Casual", "Popular", "Puzzle", "Arcade"];

  const handleFilterChange = (category) => {
    setSelectedCategory(category);

    const filteredCards = initialCards.filter(
      (card) => card.category === category
    );
    setCards(filteredCards);
  };

  return (
    <>
      <AppHeader name="games" indexSlide={2} />
      <>
        <Slider {...settings}>
          <div className="slide1">
            <div className="bg1"></div>
            {/* <div className="mobile">
              <img src={mobile} alt="" />
            </div> */}
            <div className="mobile-video">
              <video autoPlay muted loop src={mobile_video}></video>
            </div>{" "}
            <div className="slide1-heading">
              <img src={knife_show_title} alt="" className="title-img" />

              <p className="slide1-text">
                Ultimate Knife showdown with endless gameplay with multiple
                knives, thrilling challenges, and much more. It is a
                single-player knife-throw game with intuitive controls and easy
                gameplay.
              </p>
              <div className="downloads">
                <Link to="https://play.google.com/store/apps/details?id=com.appspace.knifehit">
                  <img src={playstore} alt="" />
                </Link>
                <Link to="https://apps.apple.com/us/app/apple-store/id6467878932">
                  <img src={appstore} alt="" />
                </Link>
              </div>
            </div>
            <div className="juggler">
              <img src={juggler} alt="" />
            </div>
          </div>

          <div className="slide2">
            <div className="bg2"></div>

            <div className="slide2-heading">
              <h1>War Tank</h1>
              <h2>Ball Blast</h2>
              <p>
                War Tank is an engaging game as it has epic battles, challenges,
                and so much to offer. So gear up for adrenaline-pumping showdown
                in War Tank game!
              </p>
              <div className="downloads">
                <Link to="https://play.google.com/store/apps/details?id=com.appspace.ballblast">
                  <img src={playstore} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </Slider>
        <div className="main-content-games">
          <div className="heading-container">
            <div className="game-text">
              {" "}
              <h1>OUR MOST LOVED GAMES</h1>
              <p>
                You can explore multiple games that you will love and leave you
                with nostalgia that you will cherish. You can try out our
                awesome loved games with unique features, twists, and much more.
              </p>
            </div>
          </div>

          <Slider {...settings2} className="games">
            <div className="game">
              <img
                src={joker}
                alt=""
                className="game-img"
                width={250}
                height={250}
              />
              <h2>KNIFE SHOW</h2>
              <p>Slice up the Knife Show game with thrilling Boss level.</p>
              <Link to="https://play.google.com/store/apps/details?id=com.appspace.knifehit">
                <img src={playstore} alt="" className="playstore" />
              </Link>
            </div>
            <div className="game">
              <img
                src={tap_tap_ball}
                alt=""
                className="game-img"
                width={250}
                height={250}
              />
              <h2>KNIFE SHOW PRO</h2>
              <p>Slice up the Knife Show Pro game with thrilling Boss level.</p>
              <Link to="https://play.google.com/store/apps/details?id=com.appspace.knifehit">
                <img src={playstore} alt="" className="playstore" />
              </Link>
            </div>
            {/* <div className="game">
              <img
                src={stack_ball}
                alt=""
                className="game-img"
                width={250}
                height={250}
              />
              <h2>STACK BALL</h2>
              <p>Drop, Smash, and Conquer the Stacked Tower!</p>
              <Link to="https://play.google.com/store/apps/details?id=com.dropstackball.game">
                <img src={playstore} alt="" className="playstore" />
              </Link>
            </div> */}
            <div className="game">
              <img
                src={war_tank}
                alt=""
                className="game-img"
                width={250}
                height={250}
              />
              <h2>WAR TANK</h2>
              <p>Command and dominate the battlefield in War Tank!</p>
              <Link to="https://play.google.com/store/apps/details?id=com.appspace.ballblast">
                <img src={playstore} alt="" className="playstore" />
              </Link>
            </div>
            <div className="game">
              <img
                src={locker}
                alt=""
                className="game-img"
                width={250}
                height={250}
              />
              <h2>WOODIE BRICKS</h2>
              <p>Stack, Strategize, and conquer with Woodie Brick.</p>
              <Link to="https://play.google.com/store/apps/details?id=com.appspace.wood.block.puzzle">
                <img src={playstore} alt="" className="playstore" />
              </Link>
            </div>
          </Slider>

          <div className="explore-button">
            <Link to="https://play.google.com/store/apps/dev?id=5808132673176532737">
              <button>EXPLORE MORE</button>
            </Link>
          </div>
          <div className="multiple-categories">
            <h1>MULTIPLE CATEGORIES</h1>
            <FilterBar
              categories={categories}
              selectedCategory={selectedCategory}
              onFilterChange={handleFilterChange}
            />
            <CardList cards={cards} />
          </div>
          <div className="game-faq">
            <h1>FREQUENTLY ASKED QUESTIONS</h1>
          </div>
          <div className="faq-game-accordion flex flex-col justify-center items-center mt-16 w-[100vw]">
            <Accordion
              className=" text-[1.3vmax] faq-accordion-container "
              allowZeroExpanded={true}
            >
              <AccordionItem className=" accordion-game-item rounded-xl mb-5 p-6">
                <AccordionItemHeading className=" ">
                  <AccordionItemButton
                    className=" accordion-game-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                  >
                    Are these games available on multiple platforms?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-game-answer">
                  Yes, you can play them on multiple platforms like mobile
                  device and web browser.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className=" accordion-game-item rounded-xl mb-5 p-6">
                <AccordionItemHeading className=" ">
                  <AccordionItemButton
                    className=" accordion-game-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                  >
                    Do these game provide difficulty levels to make games more
                    interesting?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-game-answer">
                  Yes, most of these games cater different difficulty levels to
                  level up the game.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className=" accordion-game-item rounded-xl mb-5 p-6">
                <AccordionItemHeading className=" ">
                  <AccordionItemButton
                    className=" accordion-game-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                  >
                    Are there any bonus or rewards to enhance the gameplay?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-game-answer">
                  Yes, some of these games provides bonus and rewards, powerplay
                  in overcoming challenges.
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem className=" accordion-game-item rounded-xl mb-5 p-6">
                <AccordionItemHeading className=" ">
                  <AccordionItemButton
                    className=" accordion-game-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                  >
                    How do you control the movement of ball in Flappy Ball game?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="accordion-game-answer">
                  In Flappy Ball you can control ball movement by tapping on
                  screen as with each tap ball jumps and complete obstacles.
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </>
      <FooterNew bgColor="#2E558D" />
    </>
  );
};

export default Games;
