import React from 'react';
import AppHeader from "../../Comp/AppHeader";
import hd1080 from "./assets/1080hd.png";
import hd720 from "./assets/720hd.png";
import hd4k from "./assets/4khd.png";
import ultrahd from "./assets/ultrahd.png";
import HDX_Logo from "./assets/HDX_Logo.svg";
import google_play_button from "./assets/google_play_button.svg";
import './hdxvideo.scss'; 
import Speciality from './Speciality';
import MoreApps from './MoreApps';
import Keyfeatures from "./Keyfeatures";


export default function Hdx() {

  return (
    <div className='bg-black'>
    <AppHeader name="hdxvideoplayer" indexSlide={3} />
    <div className="background-container ">
    <div className="background-image ">
     <div className='absolute bottom-[12vh] md:bottom-[17vh] lg:bottom-[19vh] w-[100%] flex justify-center gap-2 '>
      <img src={hd1080} alt="1080PHD" className='w-[57px] h-[55px] md:w-[76px] md:h-[74px]  lg:w-[82px] lg:h-[80px]'/>
      <img src={hd4k} alt="4K ultra HD" className='w-[57px] h-[55px]  md:w-[76px] md:h-[74px]  lg:w-[82px] lg:h-[80px]'/>
      <img src={ultrahd} alt="HD ULTRA" className='w-[57px] h-[55px]  md:w-[76px] md:h-[74px]  lg:w-[82px] lg:h-[80px]'/>
      <img src={hd720} alt="720PHD " className='w-[57px] h-[55px]  md:w-[76px] md:h-[74px]  lg:w-[82px] lg:h-[80px]'/>
      </div>
      <div className='absolute bottom-0 translate-y-6 md:translate-y-0     w-[100%]  md:h-[100px] md:flex  justify-center items-center gap-8'>
      <div className=' relative text-white pb-3 font-inter text-[12px] md:text-[16px] md:font-medium leading-normal'>HDx Video Player is a free HD video player for <br/> Android that offers multiple advanced features.
      </div>
      <span className='border h-[50px] hidden md:block'></span>
      <div className='flex justify-center  gap-8 '>
      <img src={HDX_Logo} alt='Hdx logo all format'  className='w-[100px] h-[35px] md:w-[127px] md:h-[55px] lg:h-[70px] '/>
      <img src={google_play_button} alt="google_play_button" className='w-[100px] h-[35px] md:w-[127px] md:h-[53px] lg:h-[70px] '/>
      </div>
      </div>
    </div>
  </div>
  <Keyfeatures/>
  <Speciality/>
  <MoreApps/>
  </div>
  );
}
