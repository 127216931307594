import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "../Screens/faq.css";

const FaqAll = () => {
  return (
    <div className=" mx-auto w-[90%]   lg:w-3/4 mt-14 grid grid-cols-1 lg:grid-cols-2 gap-10 font-sans">
      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">Does it support free-style collages?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can create freestyle collages with APS collage maker and
              photo editor app.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                What are some features to look for in a collage maker app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Collage maker app includes the ability to choose from a variety of
              collage layouts, free style collages, add text to the collage,
              adjust the size and shape of the images, and add filters or other
              effects{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I save my collages as a high-resolution images using a
                collage maker app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, most collage maker apps allow you to save your collages as
              high-resolution images that you can print or share digitally.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black  font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I customize the layout of a collage using a collage maker
                app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, most collage maker apps allow you to customize the layout of
              a collage by choosing from a variety of templates or by adjusting
              the size and shape of the images.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                How many photos can I include in a collage using this collage
                maker app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              With this app, you can add a maximum of 9 photos in grid collages
              and free-style collages.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I share my collages created using a collage maker app on
                social media?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, this collage maker app allows you to share your collages on
              social media platforms such as Instagram, Facebook, and Twitter.
              Also, you can share via Bluetooth and other sharing platforms.{" "}
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Accordion className="" allowZeroExpanded={true}>
        <AccordionItem className="bg-white rounded-2xl ">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
                  !text-black font-semibold
                   !rounded-lg"
            >
              <p className="w-3/4">Is it free or do I have to pay for it?</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              This app is free to download and use, you don't have to go for a
              one-time purchase or a subscription to access all editing
              features.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I print collages created with a collage maker app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can print collages created with a collage maker app. Once
              you have saved your collage, you can share it on your
              computer/laptop or send it to a printing service.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Can I edit photos or just create collage with this app?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, with the collage maker app, you can edit your photos with
              multiple editing tools along withj free style collages and grid
              collage maker.
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Is it possible to share this app with my friends ?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              Yes, you can share this app with other people via any social media
              platform or any other apps sharing platforms
            </p>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem className="bg-white rounded-2xl mt-10">
          <AccordionItemHeading className="accordion__arrow">
            <AccordionItemButton
              className="accordion__button !bg-transparent
               !text-black font-semibold
                !rounded-lg"
            >
              <p className="w-3/4">
                Query not mentioned here! How can I contact you?
              </p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="!text-gray-900 ">
              If you have not found your query here, you can get in touch with
              our support team so we can help you better. <br />
              You may contact us at{" "}
              <a href="mailto: feedback@appspacesolutions.in">
                <strong>feedback@appspacesolutions.in</strong>
              </a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FaqAll;
