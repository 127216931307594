import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const scroll = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(scroll, [pathname]);
  return;
};

export default ScrollToTop;
