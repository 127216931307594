import React, { useState } from "react";
import { useEffect } from "react";
import AppHeader from "../Comp/AppHeader";
import Apps from "../Comp/Apps";
import Carousel from "../Comp/Carousel";
import FooterNew from "../Comp/FooterNew";
import Offer from "../Comp/Offer";
import ReViews from "../Comp/ReViews";
import { Helmet } from "react-helmet";

const AppSpaceHomePage = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    console.log(`Current Index is ${index}`);
  }, [index]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          AppSpace Solutions Best Productivity Mobile App Development Company
        </title>
        <meta
          name="description"
          content="AppSpace Solutions is the best Productivity mobile app development company. Our team develops premium reliable and user-friendly Android and iOS apps for users."
        />
      </Helmet>
      <AppHeader name="home" indexSlide={index} />
      <Carousel setIndex={setIndex} />
      <Apps />
      <Offer />
      <ReViews />
      <FooterNew bgColor="#ff6200" />
    </>
  );
};

export default AppSpaceHomePage;
