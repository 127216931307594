import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { scroll } from "./ScrollToTop";
import "react-modern-drawer/dist/index.css";
import "../App.css";

const AppHeader = ({ name = "home", indexSlide }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  console.log(menuOpen);

  return (
    <header
      className={`backdrop-blur-xl  bg-gray-900/50  lg:bg-gray-900/20 fixed w-full z-50 
   ${
     indexSlide === 0 ||
     indexSlide === 1 ||
     name === "contact" ||
     name === "about"
       ? "text-black"
       : "text-white"
   } 
   py-1 px-10 lg:px-16 flex items-center justify-between h-16 `}
    >
      <Link to="/">
        <div
          className="flex items-center justify-center space-x-2 cursor-pointer"
          onClick={scroll}
        >
          <img
            src={
              indexSlide === 0 ||
              indexSlide === 1 ||
              indexSlide === 2 ||
              name === "contact" ||
              name === "about"
                ? "/images/icon/bule_2-1.png"
                : "/images/icon/white_2.png"
            }
            alt="appspace solutions"
            className="w-10 lg:w-12 h-10 lg:h-12"
          />
          <div className=" pt-[4px]">
            <h5 className="text-[14px] lg:text-md mb-0.5 font-bold">AppSpace</h5>
            <h5 className="text-[12px] lg:text-sm ">Solutions</h5>
          </div>
        </div>
      </Link>

      <div className="block lg:hidden pt-2">
        <button className="" onClick={toggleDrawer}>
          {" "}
          <div>
            {isOpen === false ? (
              <GiHamburgerMenu size={25} />
            ) : (
              <MdClose size={20} />
            )}
          </div>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="backdrop-blur-xl  h-screen mt-16
                 bg-gray-900/70 "
        >
          <div
            className={` flex flex-col items-center justify-start pt-2 h-full space-y-8 overflow-y-auto`}
          >
            <Link
              to="/"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "home" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                Home
              </h3>
            </Link>
            <Link
              to="/games"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "games" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                Games
              </h3>
            </Link>

            <Link
              to="/about"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "about" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                About{" "}
              </h3>
            </Link>
            <Link
              to="/faq"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "faq" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                FAQs{" "}
              </h3>
            </Link>
            <Link
              to="/blogs"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "blogs" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                Blogs{" "}
              </h3>
            </Link>
            <Link
              to="/contact"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "contact" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                Contact{" "}
              </h3>
            </Link>
            <Link
              to="/privacy"
              onClick={() => setMenuOpen(false)}
              style={{ color: "#FFF" }}
            >
              <h3
                className={`${
                  name === "privacy" ? "font-bold text-xl" : "font-[50] text-lg"
                }`}
              >
                Privacy
              </h3>
            </Link>
          </div>
        </Drawer>
      </div>

      {/* <div className='lg:hidden'
        onClick={()=>{
           setMenuOpen(!menuOpen)
        }}>
          <h2>{menuOpen===false?<GiHamburgerMenu size={30}/>:<MdClose size={30}/>}</h2>
       {
        menuOpen === true ? 
        <>
          <div 
          onClick={()=>setMenuOpen(false)}
          className='h-screen  w-2/3   absolute top-16 left-0 z-30'>

          </div>
          <div className={` backdrop-blur-3xl  space-y-5 bg-gray-900/50  lg:bg-gray-900/20 rounded-b-2xl
        absolute top-16 right-5 h-72 py-4 px-8 text-white justify-center  items-center flex flex-col   `}>
        
        <Link to="/" onClick={()=>setMenuOpen(false)} style={{ color:'#FFF' }} >
         <h3 className={`${name==="home"?"font-bold text-xl":"font-[50] text-lg"}`}>Home</h3>
        </Link>
        <Link to="/about" onClick={()=>setMenuOpen(false)} style={{ color:'#FFF' }}>
         <h3 className={`${name==="about"?"font-bold text-xl":"font-[50] text-lg"}`}>About  </h3>
        </Link>
        <Link to="/contact" onClick={()=>setMenuOpen(false)} style={{ color:'#FFF' }}>
         <h3 className={`${name==="contact"?"font-bold text-xl":"font-[50] text-lg"}`}>Contact </h3>
        </Link>
        <Link to="/privacy" onClick={()=>setMenuOpen(false)} style={{ color:'#FFF' }}>
         <h3 className={`${name==="privacy"?"font-bold text-xl":"font-[50] text-lg"}`}>Privacy</h3>
        
        </Link>
   </div>
        </>
      
        : null
       }
    
        </div> 
    */}

      <div
        className={` hidden  justify-center  items-center lg:flex  space-x-6  `}
      >
        <Link
          to="/"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about"
                ? "#000"
                : "white",
          }}
        >
          <h3
            className={`${
              name === "home" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            Home
          </h3>
        </Link>
        <Link
          to="/games"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "games" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            Games
          </h3>
        </Link>
        <Link
          to="/about"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "about" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            About{" "}
          </h3>
        </Link>
        <Link
          to="/faq"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about" ||
              name === "contact"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "faq" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            FAQs{" "}
          </h3>
        </Link>
        <Link
          to="/blogs"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about" ||
              name === "contact"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "blogs" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            Blogs{" "}
          </h3>
        </Link>
        <Link
          to="/contact"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "contact" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            Contact{" "}
          </h3>
        </Link>
        <Link
          to="/privacy"
          style={{
            color:
              indexSlide === 0 ||
              indexSlide === 1 ||
              name === "contact" ||
              name === "about"
                ? "#000"
                : "#FFF",
          }}
        >
          <h3
            className={`${
              name === "privacy" ? "font-bold text-xl" : "font-[50] text-lg"
            }`}
            onClick={scroll}
          >
            Privacy
          </h3>
        </Link>
      </div>
    </header>
  );
};

export default AppHeader;
