import React from 'react';
import icon1 from "./assets/specialicons/play.png";
import icon2 from "./assets/specialicons/music.png";
import icon3 from "./assets/specialicons/roller_brush.png";
import icon4 from "./assets/specialicons/gallary.png";
import icon5 from "./assets/specialicons/cast.png";
import icon6 from "./assets/specialicons/more.png"; 

const data = [
  {
    icon: icon1,
    title: "All Format Video Player",
    desc: "Support all possible formats of video like 8k, 4k, MKV, MPEG, MP4 etc",
    background:"yellow"
  },
  {
    icon: icon2,
    title: "Music Player",
    desc: "Search and Manage Track, Artist, Albums, Genre, Recent playlist and create playlist.",
    background:"pink"
  },
  {
    icon: icon3,
    title: "Customise Themes",
    desc: "Search and Manage Track, Artist, Albums, Genre, Recent playlist and create playlist.",
    background:"red"
  },
  {
    icon: icon4,
    title: "More features",
    desc: "Reels Downloader, Status Saver, Dual audio, Video Downloader, PIP mode & much more.",
    background:"green"
  },
  {
    icon: icon5,
    title: "More features",
    desc: "Reels Downloader, Status Saver, Dual audio, Video Downloader, PIP mode & much more.",
    background:"blue"
  },
  {
    icon: icon6,
    title: "More features",
    desc: "Reels Downloader, Status Saver, Dual audio, Video Downloader, PIP mode & much more.",
    background:"purple"
  },
];

export default function Speciality() {
  return (
    <section className="bg-black w-full px-[20px] sm:px-[50px] md:px-[100px] lg:px-[150px] pb-20">
      <h4 className="text-white font-inter text-lg md:text-xl font-semibold leading-normal mb-6">
        SPECIALITIES
      </h4>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 flex-wrap">
      {data.map((item, index) => (
  <div key={index} className={`flex md:block gap-1 md:gap-4  justify-center p-6 ${item.background} `} >
  <div className='flex justify-center mb-3'>
    <img
      src={item.icon}
      alt="dynamic icon"
      className="w-16 h-16 md:w-20 md:h-20 object-contain "
    />
  </div>
    <div className="w-full text-center ">
      <p className="text-white text-center font-inter text-[18px] font-semibold leading-normal">
        {item.title}
      </p>
      <p className="text-white text-center font-inter text-[14px] font-normal leading-[1.21]">
        {item.desc}
      </p>
    </div>
  </div>
))}

      </div>
    </section>
  );
}

