import React, { useEffect } from "react";

function FilterBar({ categories, selectedCategory, onFilterChange }) {
  useEffect(() => onFilterChange(selectedCategory), []);
  return (
    <div>
      <div className="category-filter">
        {categories.map((category) => (
          <div
            key={category}
            className={`category-box ${
              selectedCategory === category ? "active" : ""
            }`}
            onClick={() => onFilterChange(category)}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilterBar;
